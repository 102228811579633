import classNames from 'classnames';
import * as React from 'react';

import type { FontSize } from '~/lib/types';

import styles from './text.module.css';

interface TextProps {
  as?: 'p' | 'blockquote';
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
  color?: 'normal' | 'secondary' | 'primary';
  bold?: boolean;
  size?: FontSize;
  uppercase?: boolean;
  underline?: boolean;
}

export function Text({
  as = 'p',
  bold,
  center,
  children,
  className,
  color = 'normal',
  uppercase,
  underline,
  size,
}: TextProps) {
  const Component = as;

  return (
    <Component
      className={classNames(className, {
        [styles.bold]: bold,
        [styles.center]: center,
        [styles.primary]: color === 'primary',
        [styles.uppercase]: uppercase,
        [styles.underline]: underline,
        [styles.secondary]: color === 'secondary',
      })}
      style={{ fontSize: size ? `var(--font-size-${size})` : 'inherit' }}
    >
      {children}
    </Component>
  );
}
