import * as React from 'react';

/**
 * The Chameleon component is an abstract component where the element type can
 * be set dynamically. Mainly used by layout components atm, which is why it is
 * included here, although it could be used elsewhere in the future.
 */

export type ChameleonProps<TElementType extends React.ElementType> = {
  as?: TElementType;
  children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<TElementType>;

export function Chameleon<TElementType extends React.ElementType = 'div'>({
  as,
  ...rest
}: ChameleonProps<TElementType>) {
  const Component = as || 'div';
  return <Component {...rest} />;
}
