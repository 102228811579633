import classNames from 'classnames';
import * as React from 'react';

import type { StackProps } from '../stack';

import { Stack } from '../stack';
import styles from './container.module.css';

export type ContainerProps<TElementType extends React.ElementType> = StackProps<TElementType>;

export function Container<TElementType extends React.ElementType = 'div'>({
  className,
  ...rest
}: ContainerProps<TElementType>) {
  return <Stack {...rest} className={classNames(styles.container, className)} />;
}
