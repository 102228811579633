import classNames from 'classnames';
import * as React from 'react';

import styles from './button.module.css';

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> {
  children: React.ReactNode;
  /** Removes background color. */
  bordered?: boolean;

  // Since there is just one color option for now, this prop is not available yet:
  // /** Defaults to primary. */
  // color?: 'primary';
}

export function Button({ children, className, bordered, ...rest }: ButtonProps) {
  return (
    <button
      {...rest}
      className={classNames(styles.button, 'form-pill', className, {
        [styles.bordered]: bordered,
        [styles.primary]: true, // Should eventually be replaced with `color === 'primary'`
      })}
      data-ghost={bordered}
    >
      {children}
    </button>
  );
}
