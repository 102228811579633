import * as React from 'react';

import styles from './input.module.css';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'primary';
}

export function Input({ variant = 'primary', ...rest }: InputProps) {
  return <input {...rest} className={`${styles.input} form-pill`} data-variant={variant} />;
}
