import classNames from 'classnames';
import * as React from 'react';

import type { ChameleonProps } from '~/layouts/chameleon';

import styles from './pile.module.css';

import { Chameleon } from '~/layouts/chameleon';

type PileProps<TElementType extends React.ElementType> = {
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyItems'];
} & ChameleonProps<TElementType>;

export function Pile<TElemenType extends React.ElementType>({
  align,
  className,
  justify,
  style,
  ...rest
}: PileProps<TElemenType>) {
  return (
    <Chameleon
      {...rest}
      className={classNames(styles.pile, className)}
      style={{
        ...style,
        alignItems: align,
        justifyItems: justify,
      }}
    />
  );
}
