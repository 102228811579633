import classNames from 'classnames';
import * as React from 'react';

import type { ChameleonProps } from '~/layouts/chameleon';
import type { SpaceSize } from '~/lib/types';

import styles from './stack.module.css';

import { Chameleon } from '~/layouts/chameleon';

export type StackProps<TElementType extends React.ElementType> = {
  align?: React.CSSProperties['alignItems'];
  fullWidth?: boolean;
  gap?: SpaceSize;
  grow?: boolean;
  justify?: React.CSSProperties['justifyContent'];
} & ChameleonProps<TElementType>;

export function Stack<TElementType extends React.ElementType = 'div'>({
  align,
  className,
  fullWidth = false,
  gap = '3',
  grow = false,
  justify = 'flex-start',
  style,
  ...rest
}: StackProps<TElementType> & React.ComponentPropsWithoutRef<TElementType>) {
  return (
    <Chameleon
      {...rest}
      className={classNames(styles.stack, className)}
      style={{
        alignItems: align,
        flexGrow: grow ? '1' : 'initial',
        justifyContent: justify,
        width: fullWidth ? '100%' : '',
        ...style,
        ...{ '--gap': `var(--size-${gap}` },
      }}
    />
  );
}
